$.initTinyMCE = function(){
  if (document.getElementsByClassName('tinymce').length > 0 || document.getElementsByClassName('tinymce-medium').length > 0 || document.getElementsByClassName('tinymce-basic').length > 0) {
    tinymce.remove(); // needed to fix issue with turbolinks
  }

  if (document.getElementsByClassName('tinymce').length > 0) {
    TinyMCERails.initialize('default', {});
  }
  if (document.getElementsByClassName('tinymce-medium').length > 0) {
    TinyMCERails.initialize('medium', {});
  }
  if (document.getElementsByClassName('tinymce-basic').length > 0) {
    TinyMCERails.initialize('basic', {});
  }
};

$.updateAllTinyMCEAssociatedTextareas = function(){
  tinymce.triggerSave();
};

["turbo:load", "turbo:render"].forEach((event) => {
  document.addEventListener(event, function() {
    $.initTinyMCE();
  });
});
