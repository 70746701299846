import data from 'emoji-mart/data/apple.json'
import { NimblePicker } from 'emoji-mart'
import React from 'react'
import ReactDOM from 'react-dom'

class TextareaEmojis {
  start() {
    document.addEventListener("turbo:load", function() {
      this.popupElement = document.querySelector('.js-emojis-popup');
      this.popupToggle = document.querySelector('.js-emojis-toggle');

      if(this.popupElement && this.popupToggle) {
        this.textareaTarget = this.popupToggle.getAttribute('data-target');
        this.textareaElement = document.querySelector(this.textareaTarget);
        this.formElement = document.querySelector('form.write-message');

        this.Picker = React.createElement(NimblePicker, {
          set: 'apple',
          data,
          title: 'Emojis',
          onSelect: this._handleTextareaInsert.bind(this)
        });
        this.isMounted = false;

        this.popupToggle.addEventListener('click', this._handleEmojisClick.bind(this));
        this.textareaElement.addEventListener('keydown', this._handleEnterSubmit.bind(this));

        this.textareaElement.focus();
      }
    }.bind(this))
  }

  _handleEnterSubmit(ev) {
    // comment these lines for stop message sending in conversations.
    // if(ev.keyCode == 13 && !ev.shiftKey){
    //     this.formElement.submit();
    //     ev.preventDefault();
    // }
  }

  _handleEmojisClick() {
    if(this.isMounted) {
      ReactDOM.unmountComponentAtNode(this.popupElement);
      this.isMounted = false;
      this.popupElement.classList.remove('is-opened');

      window.setTimeout(function() {

      }.bind(this), 2500);
    } else {
      ReactDOM.render(this.Picker, this.popupElement);
      this.isMounted = true;
      this.popupElement.classList.add('is-opened');
    }
  }

  _handleTextareaInsert(emoji) {
    this._insertAtCursor(this.textareaElement, emoji.native);
  }

  _insertAtCursor(myField, myValue) {
    //IE support
    if (document.selection) {
      myField.focus();
      sel = document.selection.createRange();
      sel.text = myValue;
    }
    //MOZILLA and others
    else if (myField.selectionStart || myField.selectionStart == '0') {
      var startPos = myField.selectionStart;
      var endPos = myField.selectionEnd;
      myField.value = myField.value.substring(0, startPos)
          + myValue
          + myField.value.substring(endPos, myField.value.length);
    } else {
      myField.value += myValue;
    }
  }
}

export default TextareaEmojis
