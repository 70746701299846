// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
// import * as bootstrap from "bootstrap"

// Rails UJS
import Rails from '@rails/ujs';
Rails.start();

import * as moment from 'moment';
window.moment = moment;

import grapesjs from 'grapesjs';
import 'grapesjs-blocks-basic';
import 'grapesjs-custom-code';

// Sub directory
import './src/plugins'
import "./controllers"
import '@nathanvda/cocoon';
import './src/application'

// Yarn add @doabit/semantic-ui-sass
// Some sass dependencies not compatible with our app, so add useful files.
import './src/semantic-ui-sass/src/semantic-ui';
