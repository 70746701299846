class DetailsModal {
  start() {
    document.addEventListener("turbo:load", function() {
      this.initialize();
    }.bind(this))
  }

  initialize() {
    this.allModals = document.getElementsByClassName('modal')

    var detailsLinks = document.getElementsByClassName('js-show-details')
    for (var i = 0, len = detailsLinks.length; i < len; i++) {
      this.addShowNoteEventListener(detailsLinks[i])
    }

    var classesToCloseModal = ['modal-background', 'modal-close', 'js-fullmodal-close']
    for (var i = 0, len = classesToCloseModal.length; i < len; i++) {
      var closeElements = document.getElementsByClassName(classesToCloseModal[i])
      for (var j = 0, len2 = closeElements.length; j < len2; j++) {
        this.addCloseModalEventListener(closeElements[j])
      }
    }

    var toolsLinks = document.getElementsByClassName('copy-tools-link')
    for (var i = 0, len = toolsLinks.length; i < len; i++) {
      this.copyToolsLinkToClipBoard(toolsLinks[i])
    }
  }

  copyToolsLinkToClipBoard(link) {
    link.addEventListener('click', function(event){
      event.preventDefault()
      var toolUrl = document.getElementById('public_url')
      if(toolUrl) {
        toolUrl.select();
        document.execCommand('copy');
        event.currentTarget.innerHTML = 'Copied!';
      }
    })
  };

  addShowNoteEventListener(link) {
    link.addEventListener('click', function(event){
      event.preventDefault()
      var modalElement = event.currentTarget.parentElement.getElementsByClassName('js-show-details-modal')[0]
      modalElement.classList.add('is-active')
    })
  }

  addCloseModalEventListener(closeElement){
    closeElement.addEventListener('click', function(event){
      event.preventDefault()
      for (var i = 0, len = this.allModals.length; i < len; i++) {
        this.allModals[i].classList.remove('is-active')
      }
    }.bind(this))
  }
}

window.initializeDetailsModal = function() {
  var detailsModal = new DetailsModal();
  detailsModal.initialize();
}

export default DetailsModal
