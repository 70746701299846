import { I18n } from "i18n-js";
import fr from "../../translations/fr.json";
import en from "../../translations/en.json";

function showHideQuestionAnswerType(event) {
  let question_answer_type_select = document.getElementById('question_answer_type');
  let question_answer_type_for_choices = document.getElementById('js-for-answer-type-choices');
  let question_answer_type_for_choices_single = document.getElementById('js-for-answer-type-choices-single');
  let question_answer_type_for_choices_multiple = document.getElementById('js-for-answer-type-choices-multiple');
  let question_answer_type_for_choices_missing_word = document.getElementById('js-for-answer-type-choices-missing-word');
  let question_answer_type_for_choices_matching_question = document.getElementById('js-for-answer-type-choices-matching-question');
  let question_answer_type_for_choices_wrong_matching_question = document.getElementById('js-for-answer-type-choices-wrong-matching-question');

  let missing_word_hint = document.getElementById('missing-word-hint');
  let question_answer_type_for_choices_true_false = document.getElementById('js-for-answer-type-choices-true-false');
  let question_answer_type_for_choices_yes_no = document.getElementById('js-for-answer-type-choices-yes-no');
  let value_question = document.getElementById('is-value-question');
  let answer_weighting = document.getElementById('js-answer-weighting');
  let is_value_question = document.getElementById('question_is_value_question')
  let correct_date = document.getElementById('js-correct-date')
  let all_value_questions_paragraph = document.getElementById('all-value-questions-paragraph')
  let missing_word_question_paragraph = document.getElementById('missing-word-question-paragraph')
  let choice_answers = document.getElementById('choice-answers')
  let choice_answers_question = document.getElementById('choice-answers-question');
  let choice_order_answers_question = document.getElementById('choice-order-answers-question');

  if (question_answer_type_select && question_answer_type_for_choices) {
    if (question_answer_type_select.value == 'single_choice' || question_answer_type_select.value == 'multiple_choices' ||question_answer_type_select.value == 'true_false' || question_answer_type_select.value == 'yes_no' || question_answer_type_select.value == 'missing_word' || question_answer_type_select.value == 'order_question' || question_answer_type_select.value == 'matching_question') {
      question_answer_type_for_choices.classList.remove("hidden");
      value_question.classList.add("hidden");
      correct_date.classList.add("hidden");
      question_answer_type_for_choices_missing_word.classList.add("hidden");
      question_answer_type_for_choices_matching_question.classList.add("hidden");
      question_answer_type_for_choices_wrong_matching_question.classList.add("hidden");
      missing_word_hint.classList.add("hidden");
      all_value_questions_paragraph.classList.remove("hidden");
      missing_word_question_paragraph.classList.add("hidden");
      choice_answers.classList.remove('hidden');
      choice_answers_question.classList.remove('hidden');
      choice_order_answers_question.classList.remove('hidden');
      if (question_answer_type_select.value == 'single_choice') {
        question_answer_type_for_choices_single.classList.remove("hidden");
        question_answer_type_for_choices_multiple.classList.add("hidden");
        question_answer_type_for_choices_true_false.classList.add("hidden");
        question_answer_type_for_choices_yes_no.classList.add("hidden");
      } else if (question_answer_type_select.value == 'multiple_choices') {
        question_answer_type_for_choices_multiple.classList.remove("hidden");
        question_answer_type_for_choices_single.classList.add("hidden");
        question_answer_type_for_choices_true_false.classList.add("hidden");
        question_answer_type_for_choices_yes_no.classList.add("hidden");
      } else if (question_answer_type_select.value == 'true_false') {
        question_answer_type_for_choices_true_false.classList.remove("hidden");
        question_answer_type_for_choices_single.classList.add("hidden");
        question_answer_type_for_choices_multiple.classList.add("hidden");
        question_answer_type_for_choices_yes_no.classList.add("hidden");
      } else if (question_answer_type_select.value == 'missing_word' || question_answer_type_select.value == 'order_question') {
        if (question_answer_type_select.value == 'missing_word') {
          missing_word_hint.classList.remove("hidden")
          missing_word_question_paragraph.classList.remove("hidden");
          all_value_questions_paragraph.classList.add("hidden");
          choice_answers_question.classList.remove('hidden');
          choice_order_answers_question.classList.add('hidden');
        } else {
          missing_word_hint.classList.add("hidden")
          missing_word_question_paragraph.classList.add("hidden");  
          all_value_questions_paragraph.classList.remove("hidden");
          choice_answers_question.classList.add('hidden');
          choice_order_answers_question.classList.remove('hidden');
          showHideCorrectOrderQuestionOptions();
        }
        question_answer_type_for_choices_missing_word.classList.remove("hidden");
        question_answer_type_for_choices_matching_question.classList.add("hidden");
        question_answer_type_for_choices_wrong_matching_question.classList.add("hidden");
        answer_weighting.classList.remove("hidden");
        choice_answers.classList.add("hidden");
        question_answer_type_for_choices_single.classList.add("hidden");
        question_answer_type_for_choices_multiple.classList.add("hidden");
        question_answer_type_for_choices_true_false.classList.add("hidden");
        question_answer_type_for_choices_yes_no.classList.add("hidden");
      } else if (question_answer_type_select.value == 'matching_question') {
        question_answer_type_for_choices_missing_word.classList.add("hidden");
        question_answer_type_for_choices_matching_question.classList.remove("hidden");
        question_answer_type_for_choices_wrong_matching_question.classList.remove("hidden");
        answer_weighting.classList.remove("hidden");
        choice_answers.classList.add("hidden");
        choice_answers_question.classList.add('hidden');
        question_answer_type_for_choices_single.classList.add("hidden");
        question_answer_type_for_choices_multiple.classList.add("hidden");
        question_answer_type_for_choices_true_false.classList.add("hidden");
        question_answer_type_for_choices_yes_no.classList.add("hidden");
      } else {
        question_answer_type_for_choices_yes_no.classList.remove("hidden");
        question_answer_type_for_choices_single.classList.add("hidden");
        question_answer_type_for_choices_multiple.classList.add("hidden");
        question_answer_type_for_choices_true_false.classList.add("hidden");
      }
    } else if (question_answer_type_select.value == 'string' || question_answer_type_select.value == 'text') {
      value_question.classList.remove("hidden");
      correct_date.classList.add("hidden")
      question_answer_type_for_choices.classList.add("hidden");
      question_answer_type_for_choices_missing_word.classList.add("hidden");
      question_answer_type_for_choices_matching_question.classList.add("hidden");
      question_answer_type_for_choices_wrong_matching_question.classList.add("hidden");
      missing_word_hint.classList.add("hidden");
      choice_answers.classList.remove("hidden");
      choice_answers_question.classList.remove('hidden');
      if (is_value_question.checked) {
        answer_weighting.classList.remove("hidden");
      } else {
        answer_weighting.classList.add("hidden");
      }
    } else if (question_answer_type_select.value == 'date') {
      value_question.classList.remove("hidden");
      question_answer_type_for_choices.classList.add("hidden");
      question_answer_type_for_choices_missing_word.classList.add("hidden");
      question_answer_type_for_choices_matching_question.classList.add("hidden");
      question_answer_type_for_choices_wrong_matching_question.classList.add("hidden");
      missing_word_hint.classList.add("hidden");
      choice_answers.classList.remove("hidden");
      choice_answers_question.classList.remove('hidden');
      if (is_value_question.checked) {
        correct_date.classList.remove("hidden")
        answer_weighting.classList.remove("hidden");
      } else {
        answer_weighting.classList.add("hidden");
        correct_date.classList.add("hidden")
      }
    } else {
      question_answer_type_for_choices.classList.add("hidden");
      value_question.classList.add("hidden");
      question_answer_type_for_choices_missing_word.classList.add("hidden");
      question_answer_type_for_choices_matching_question.classList.add("hidden");
      question_answer_type_for_choices_wrong_matching_question.classList.add("hidden");
      missing_word_hint.classList.add("hidden");
      choice_answers.classList.remove("hidden");
      choice_answers_question.classList.remove('hidden');
    }
  }
}

function changeDescriptionOrAudioFile(event) {
  let question_question_category_select = document.getElementById('question_question_category');
  let audio_file_div = document.getElementById('audio_file_div');
  if (question_question_category_select) {
    if (question_question_category_select.value == 'audio') {
      audio_file_div.classList.remove('hidden');
    } else {
      audio_file_div.classList.add('hidden');
    }
  }
}

function showMoreChoicesSingle(event) {
  let show_more_choices = document.getElementById('js-show-more-choices-single');
  let more_choices = document.getElementById('js-more-choices-single');
  
  if (show_more_choices && more_choices) {
    more_choices.classList.remove("hidden");
    show_more_choices.classList.add("hidden");
  }
}

function showMoreChoicesMultiple(event) {
  let show_more_choices = document.getElementById('js-show-more-choices-multiple');
  let more_choices = document.getElementById('js-more-choices-multiple');
  
  if (show_more_choices && more_choices) {
    more_choices.classList.remove("hidden");
    show_more_choices.classList.add("hidden");
  }
}

function showMoreChoicesMissingWord(event) {
  let choices_missing_word = document.querySelectorAll(".show-choices-missing-word")
  let show_more_choices = document.getElementById('js-show-more-choices-missing-word');
  let more_choices = document.getElementsByClassName('js-more-choices-missing-word-' + choices_missing_word.length);
  
  more_choices[0].classList.remove("hidden");
  more_choices[0].classList.add("show-choices-missing-word");
  if (choices_missing_word.length == 14) {
    show_more_choices.classList.add("hidden");
  }
}

function showMoreChoicesMatchingQuestionColumn2(event) {
  let choices_matching_question_1 = document.querySelectorAll(".show-choices-matching-question-column-1")
  let choices_matching_question = document.querySelectorAll(".show-choices-matching-question-column-2")
  let show_more_choices = document.getElementById('js-show-more-choices-matching-question-column-2');
  let more_choices = document.getElementsByClassName('js-more-choices-matching-question-column-2-' + (choices_matching_question_1.length + choices_matching_question.length));
  
  more_choices[0].classList.remove("hidden");
  more_choices[0].classList.add("show-choices-matching-question-column-2");
  if (choices_matching_question.length == 25) {
    show_more_choices.classList.add("hidden");
  }
}

function showMoreChoicesMatchingQuestionColumn1(event) {
  const i18n = new I18n({
    fr,
    en,
  });
  let choices_matching_question_1 = document.querySelectorAll(".show-choices-matching-question-column-1")
  let choices_matching_question_2 = document.querySelectorAll(".show-choices-matching-question-column-2")
  let show_more_choices_column_1 = document.getElementById('js-show-more-choices-matching-question-column-1');
  let more_choices_column_1 = document.getElementsByClassName('js-more-choices-matching-question-column-1-' + choices_matching_question_1.length);
  
  more_choices_column_1[0].classList.remove("hidden");
  more_choices_column_1[0].classList.add("show-choices-matching-question-column-1");
  if (choices_matching_question_1.length == 25) {
    show_more_choices_column_1.classList.add("hidden");
    show_more_choices_column_2.classList.add("hidden");
  }
  $('.show-choices-matching-question-column-2').each(function(i, obj) {
    obj.children[0].children[0].children[0].placeholder = i18n.t('add_matching_question_placeholder') + " #" + (choices_matching_question_1.length + i + 2);
  });
}

function showHideCorrectOrderQuestionOptions(event) {
  let question_answer_type_select = document.getElementById('question_answer_type');
  if (question_answer_type_select && question_answer_type_select.value == 'order_question') {
    let order_choices_count = 0;
    let order_question_options = document.querySelectorAll(".choice-of-missing-word");
    Array.from(order_question_options).forEach(function(element) {
      if (element.value) {
        order_choices_count += 1;
      }
    });

    for (var i = 0; i < 15; i++) {
      let more_choices = document.getElementsByClassName('js-more-correct-choices-missing-word-' + i);
      if (i < order_choices_count) {
        more_choices[0].classList.remove('hidden');
      } else {
        more_choices[0].classList.add('hidden');
        let correct_choice_select_box = document.getElementById('question_answer_selected_choices_missing_word_' + i);
        correct_choice_select_box.value = '';
      }
    }
  }
}

function showHideCorrectMissingWordOptions(event) {
  let question_answer_type_select = document.getElementById('question_answer_type');
  let question_name = document.getElementById('question_name');
  if (question_answer_type_select && question_answer_type_select.value == 'missing_word') {
    let str = question_name.value;
    let missing_word_count = str.split('[[?]]').length - 1;
    let selected_choices_missing_word = document.querySelectorAll(".show-selected-choices-missing-word");
    for (var i = 0; i < 15; i++) {
      let more_choices = document.getElementsByClassName('js-more-correct-choices-missing-word-' + i);
      if (i < missing_word_count) {
        more_choices[0].classList.remove('hidden');
      } else {
        more_choices[0].classList.add('hidden');
        let correct_choice_select_box = document.getElementById('question_answer_selected_choices_missing_word_' + i);
        correct_choice_select_box.value = '';
      }
    }
  }
}

document.addEventListener('turbo:load', function () {
  let question_answer_type_select = document.getElementById('question_answer_type');
  if (question_answer_type_select) {
    question_answer_type_select.addEventListener('change', showHideQuestionAnswerType.bind(question_answer_type_select));
  }
  showHideQuestionAnswerType();

  let question_question_category_select = document.getElementById('question_question_category');
  if (question_question_category_select) {
    question_question_category_select.addEventListener('change', changeDescriptionOrAudioFile.bind(question_question_category_select));
  }
  changeDescriptionOrAudioFile();

  // Select the audio element
  let audioPlayer = document.getElementById('question-audio-preview');
  if(audioPlayer) {
    // Add an event listener for the 'ended' event
    audioPlayer.addEventListener('ended', function() {
      let questionnaire_questions_div = document.getElementById('questionnaire_questions');
      questionnaire_questions_div.classList.remove('hidden');
    });
  }
  
  let show_more_choices_single = document.getElementById('js-show-more-choices-single');
  let more_choices_single = document.getElementById('js-more-choices-single');
  if (show_more_choices_single && more_choices_single) {
    show_more_choices_single.addEventListener('click', showMoreChoicesSingle.bind(show_more_choices_single));
  }
  
  let show_more_choices_multiple = document.getElementById('js-show-more-choices-multiple');
  let more_choices_multiple = document.getElementById('js-more-choices-multiple');
  if (show_more_choices_multiple && more_choices_multiple) {
    show_more_choices_multiple.addEventListener('click', showMoreChoicesMultiple.bind(show_more_choices_multiple));
  }

  let show_more_choices_missing_word = document.getElementById('js-show-more-choices-missing-word');
  let more_choices_missing_word = document.getElementById('js-more-choices-missing-word');
  if (show_more_choices_missing_word && more_choices_missing_word) {
    show_more_choices_missing_word.addEventListener('click', showMoreChoicesMissingWord.bind(show_more_choices_missing_word));
  }

  let show_more_choices_matching_question_column_1 = document.getElementById('js-show-more-choices-matching-question-column-1');
  let more_choices_matching_question = document.getElementById('js-more-choices-matching-question');
  if (show_more_choices_matching_question_column_1 && more_choices_matching_question) {
    show_more_choices_matching_question_column_1.addEventListener('click', showMoreChoicesMatchingQuestionColumn1.bind(show_more_choices_matching_question_column_1));
  }

  let show_more_choices_matching_question_column_2 = document.getElementById('js-show-more-choices-matching-question-column-2');
  if (show_more_choices_matching_question_column_2 && more_choices_matching_question) {
    show_more_choices_matching_question_column_2.addEventListener('click', showMoreChoicesMatchingQuestionColumn2.bind(show_more_choices_matching_question_column_2));
  }

  let question_name = document.getElementById('question_name');
  if (question_name) {
    question_name.addEventListener('change', showHideCorrectMissingWordOptions.bind(question_name));
  }
  showHideCorrectMissingWordOptions();

  let order_questions_choices = document.querySelectorAll('.choice-of-missing-word');
  Array.from(order_questions_choices).forEach(function(element){
    element.addEventListener('change', function(event){
      showHideCorrectOrderQuestionOptions();
    });
  });

  let answer_weighting = document.getElementById('js-answer-weighting');
  let answer_radios_and_checkboxes = document.querySelectorAll('.answer-type-choices input[type=checkbox], .answer-type-choices input[type=radio]');
  Array.from(answer_radios_and_checkboxes).forEach(function(element){
    element.addEventListener('click', function(event){
      if (event.target.checked) {
        answer_weighting.classList.remove("hidden");
      }
    });
  });

  let is_value_question = document.getElementById('question_is_value_question')
  if (is_value_question) {
    is_value_question.addEventListener('click', function(event){
      let question_answer_type_select = document.getElementById('question_answer_type');
      let correct_date = document.getElementById('js-correct-date')
      if (event.target.checked) {
        answer_weighting.classList.remove("hidden");
        if (question_answer_type_select.value == 'date')
          correct_date.classList.remove("hidden")
      } else {
        answer_weighting.classList.add("hidden");
        if (question_answer_type_select.value == 'date')
          correct_date.classList.add("hidden")
      }
    });
  }

  let checked_answer_radio;
  let answer_radios = document.querySelectorAll('.answer-type-choices input[type=radio]');
  Array.from(answer_radios).forEach(function(element){
    if (element.checked) {
      checked_answer_radio = element;
    }
    element.addEventListener('click', function(event){
      if(checked_answer_radio != this){
        checked_answer_radio = this;
      } else {
        this.checked = false;
        checked_answer_radio = null;
      }
    });
  });

  let tool_prices = document.querySelectorAll('.tool-price')
  if (tool_prices) {
    Array.from(tool_prices).forEach(function(element){
      element.addEventListener('change', function(event){
        value = event.target.value
        Array.from(tool_prices).forEach(function(element){
          element.value = value
        });
      });
    });
  }
  let go_to_dashboard = document.getElementById('go-to-dashboard');
  if (go_to_dashboard) {
    go_to_dashboard.addEventListener('click', function(event){
      var form = $('#update_answer_form')[0];
      var data = $('#update_answer_form').serializeArray();
      data.push({name: "corrected_by_coach", value: true})
      $.ajax({
        data: data,
        type: 'POST',
        url: form.action,
        success: function(response) {
          window.parent.location.assign(go_to_dashboard.dataset.href)
        }
      });
    });
  }

  let reload_progression = document.getElementById('reload_user_progression_page');
  if (reload_progression) {
    reload_progression.addEventListener('click', function(event){
      window.parent.location.reload();
    });
  }

  let choices_of_missing_words = document.querySelectorAll('.choice-of-missing-word');
  if (choices_of_missing_words) {
    Array.from(choices_of_missing_words).forEach(function(element) {
      element.addEventListener('change', function(event){
        var value = event.target.value;
        var old_value = event.target.defaultValue;
        event.target.defaultValue = value;
        let dropdown_for_selecting_right_choices = document.querySelectorAll('.selected-choices-for-missing-word');
        Array.from(dropdown_for_selecting_right_choices).forEach(function(dropdown_for_selecting_right_choice) {
          let ddOptions = dropdown_for_selecting_right_choice.options;
          var isExists = false;
          for (var i = 0; i < ddOptions.length; i++) {
            if (old_value != '' && ddOptions[i].value === old_value) {
              ddOptions[i].value = value;
              ddOptions[i].text = value;
              isExists = true;
              if (value == '') {
                dropdown_for_selecting_right_choice.options.remove(i);
              }
            }
          }
          if (isExists == false) {
            dropdown_for_selecting_right_choice.options[dropdown_for_selecting_right_choice.options.length] = new Option(value, value);
          }
        });
      });
    });
  }
});
